export const REDIRECT_URLS = {
  US: {
    REACT_APP_DEV_REDIRECT_URL: "https://dev.hillsretailorder.com/",
    REACT_APP_TST_REDIRECT_URL: "https://stageaem.hillsvet.com/",
    REACT_APP_PRD_REDIRECT_URL: "https://www.hillsvet.com/",
  },
  TW: {
    REACT_APP_DEV_REDIRECT_URL: "",
    REACT_APP_TST_REDIRECT_URL: "https://stageaem.hillsvet.tw/",
    REACT_APP_PRD_REDIRECT_URL: "https://hillsvet.tw/",
  },
  KR: {
    REACT_APP_DEV_REDIRECT_URL: "",
    REACT_APP_TST_REDIRECT_URL: "http://stageaem.hillsvet.co.kr/",
    REACT_APP_PRD_REDIRECT_URL: "http://hillsvet.co.kr/",
  },
  JP: {
    REACT_APP_DEV_REDIRECT_URL: "",
    REACT_APP_TST_REDIRECT_URL: "http://stageaem.hillsvet.co.kr/",
    REACT_APP_PRD_REDIRECT_URL: "http://hillsvet.co.kr/",
  },
};
